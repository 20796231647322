import Service from "../Service";

const resource = "hydrothermaltreatmentreport/"

export default {

    list(obj, requestID) {
        return Service.post(resource + "list", obj, {
            params: { requestID },
        });
    },

    PrfHydrothermalTreatmentReportDownload(obj, requestID) {

        return Service.post(resource + "PrfHydrothermalTreatmentReportDownload", obj, {
            responseType: "blob",
            headers: {
                "Content-Type": "application/json",
            },
            params: { requestID },
        });
    },

    prfHydrothermalTreatmentReportPdf(obj, requestID) {
        return Service.post(resource + "prfHydrothermalTreatmentReportPdf", obj, {
            params: { requestID },
        });
    }

}