<template>
  <div>
    <v-card>
      <v-container>
        <v-row>
          <h3 class="mt-3 ml-6">REPORTES</h3>
          <v-spacer> </v-spacer>
        </v-row>

        <v-row>
          <v-col class="pb-0">
            <v-divider></v-divider>
          </v-col>
        </v-row>

        <v-row>
          <!-- 
          <v-col lg="6" class="pt-0">
            <v-list>
              <v-list-item>
                <v-list-item-avatar>
                  <v-btn color="success" fab @click="viewDialogProduction()"
                    ><i class="fas fa-file-excel" style="font-size: 18px"></i
                  ></v-btn>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title
                    ><b
                      ><a style="color: black">
                        Reporte de Producción de la Linea de Empaque</a
                      ></b
                    ></v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-avatar>
                  <v-btn color="success" fab @click="viewDialogSuppling()"
                    ><i class="fas fa-file-excel" style="font-size: 18px"></i
                  ></v-btn>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title
                    ><b
                      ><a style="color: black">
                        Reporte de Abastecimiento de Fruta</a
                      ></b
                    ></v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-avatar>
                  <v-btn color="success" fab @click="viewDialogHidro()"
                    ><i class="fas fa-file-excel" style="font-size: 18px"></i
                  ></v-btn>
                </v-list-item-avatar>
                <v-list-item-avatar class="mr-3">
                  <v-btn color="error" fab @click="viewDialogHidroPdf()"
                    ><i class="fas fa-file-pdf" style="font-size: 18px"></i
                  ></v-btn>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title
                    ><b
                      ><a style="color: black">
                        Reporte de tratamiento de hidrotermico</a
                      ></b
                    ></v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
        -->
        <v-col cols="12" lg="6" md="6" class="pt-0">
            <v-list>
 
                <v-list-item>
                <v-list-item-avatar>
                  <v-btn
                    fab @click="exportExcel(1, 'AP-FR-152 FORMATO DE ABASTECIMIENTO DE FRUTA LÍNEA USA. - EMPAQUE')"
                    ><v-icon>mdi-cloud-download</v-icon>
                  </v-btn>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>
                    <b><a @click="exportExcel(1, 'AP-FR-152 FORMATO DE ABASTECIMIENTO DE FRUTA LÍNEA USA. - EMPAQUE')"
                        style="color: black">
                        AP-FR-152 FORMATO DE ABASTECIMIENTO DE FRUTA LÍNEA USA. - EMPAQUE</a>
                    </b>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-avatar>
                  <v-btn
                    fab @click="exportExcel(2, 'AP-FR-101 FORMATO DE ABASTECIMIENTO DE FRUTA LÍNEA EUR. - EMPAQUE')"
                    ><v-icon>mdi-cloud-download</v-icon>
                  </v-btn>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>
                    <b><a @click="exportExcel(2, 'AP-FR-101 FORMATO DE ABASTECIMIENTO DE FRUTA LÍNEA EUR. - EMPAQUE')"
                        style="color: black">
                        AP-FR-101 FORMATO DE ABASTECIMIENTO DE FRUTA LÍNEA EUR. - EMPAQUE</a>
                    </b>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-avatar>
                  <v-btn
                    fab @click="exportExcel(3, 'EMPAQUE EUROPA - BD PRODUCCION')"
                    ><v-icon>mdi-cloud-download</v-icon>
                  </v-btn>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>
                    <b><a @click="exportExcel(3, 'EMPAQUE EUROPA - BD PRODUCCION')"
                        style="color: black">
                        EMPAQUE EUROPA - BD PRODUCCION</a>
                    </b>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-avatar>
                  <v-btn
                    fab @click="exportExcel(4, 'SALDOS EMPAQUE EUROPA. - EMPAQUE')"
                    ><v-icon>mdi-cloud-download</v-icon>
                  </v-btn>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>
                    <b><a @click="exportExcel(4, 'SALDOS EMPAQUE EUROPA. - EMPAQUE. - EMPAQUE')"
                        style="color: black">
                        FORMATO DE SALDOS DE EMPAQUE EUROPA</a>
                    </b>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-avatar>
                  <v-btn
                    fab @click="exportExcel(5, 'SALDOS EMPAQUE USA. - EMPAQUE')"
                    ><v-icon>mdi-cloud-download</v-icon>
                  </v-btn>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>
                    <b><a @click="exportExcel(5, 'SALDOS EMPAQUE USA. - EMPAQUE. - EMPAQUE')"
                        style="color: black">
                        FORMATO DE SALDOS DE EMPAQUE USA</a>
                    </b>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
               
            </v-list>
          </v-col>
        </v-row>
      </v-container>
    </v-card>


 <!-- download
              @download="PrfProductionLinePackingDownload()" -->
    <v-dialog v-model="dialogProduction" width="600">
      <v-card>
        <v-container>
          <v-row>
            <s-toolbar
             
              label="Descargar Reporte"
              excel
              @excel="ReportDownloadExcel"
              pdf
              @pdf="ReportDownloadPDF"
            >
            </s-toolbar>
          </v-row>
          <v-row>
            <v-col cols="4">
              <s-date
                label="Fecha Inicio"
                v-model="filterProduction.DateBegin"
              ></s-date>
            </v-col>
            <v-col cols="4">
              <s-date
                label="Fecha Fin"
                v-model="filterProduction.DateEnd"
              ></s-date>
            </v-col>
            <!-- <v-col cols="4">
              <s-select-definition
                label="Destino"
                :def="1181"
                v-model="filterProduction.TypeDestiny"
              >
              </s-select-definition>
            </v-col> -->
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogSuppling" width="600">
      <v-card>
        <v-container>
          <v-row>
            <s-toolbar
            label="Descargar Reporte"
              excel
              @excel="ReportDownloadExcel"
              pdf
              @pdf="ReportDownloadPDF"
              
            >
            </s-toolbar>
          </v-row>
          <v-row>
            <v-col cols="4">
              <s-date
                label="Fecha Inicio"
                v-model="filter.DateBegin"
              ></s-date>
            </v-col>
            <v-col cols="4">
              <s-date
                label="Fecha Fin"
                v-model="filter.DateEnd"
              ></s-date>
            </v-col>
            <!--<v-col cols="4">
              <s-select-definition
                label="Destino"
                :def="1181"
                v-model="filterSuppling.TypeDestiny"
              >
              </s-select-definition>
            </v-col>-->
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogHydroPdf" width="600">
      <v-card>
        <v-container>
          <v-row>
            <s-toolbar
              download
              @download="HidroReportPdfDownload()"
              label="Descargar Reporte"
            >
            </s-toolbar>
          </v-row>
          <v-row>
            <v-col cols="6">
              <s-date
                label="Fecha Inicio"
                v-model="filterHydro.DateBegin"
              ></s-date>
            </v-col>
            <v-col cols="6">
              <s-date label="Fecha Fin" v-model="filterHydro.DateEnd"></s-date>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ProductionLinePackingService from "../../../services/FreshProduction/ProductionLinePackingService";
import SupplingFruitService from "../../../services/FreshProduction/SupplingFruitService";
import _sHydrothermalTreatmentReport from "@/services/FreshProduction/HydrothermaltreatmentreportService";
import _sLotProdCalibratedService from "../../../services/FreshProduction/PrfLotProductionCalibratedService";
import _sQryConfigurationService from "@/services/QueryManager/QryConfigurationService.js";

export default {
  data() {
    return {
      filterProduction: {},
      filter: {},
      filterHydro: {},
      tunnels: [],
      Coolers: [],
      items: {},
      dialogProduction: false,
      dialogSuppling: false,
      dialogHydroPdf: false,
      ChaID: 0,
      xFlag: 0,
      report: {},
      processing : false
    };
  },

  methods: {
    ReportDownloadPDF()
    {
      if(this.xFlag == 1){
         
        let obj = {};
        obj.Flag = this.xFlag;
        obj.NameReport = "AP-FR-152 FORMATO DE ABASTECIMIENTO DE FRUTA LÍNEA USA. - EMPAQUE"; 
        obj.NameProc = "REPORT_FORMAT_SUPPLY_PACKING_USA";
        obj.DateBegin = this.filter.DateBegin;
        obj.DateEnd = this.filter.DateEnd;


        console.log("x filter", obj);
         
          _sLotProdCalibratedService
            .reportCalibratePDF(obj, this.$fun.getUserID())
            .then((r) => {

                 this.$fun.downloadFile(
                    r.data,
                    this.$const.TypeFile.PDF,
                    obj.NameReport
                    );
              
            });
      }

      if(this.xFlag == 2){
         
        let obj = {};
        obj.Flag = this.xFlag;
        obj.NameReport = "AP-FR-101 FORMATO DE ABASTECIMIENTO DE FRUTA LÍNEA EUR. - EMPAQUE"; 
        obj.NameProc = "REPORT_FORMAT_SUPPLY_PACKING_EUR";
        obj.DateBegin = this.filter.DateBegin;
        obj.DateEnd = this.filter.DateEnd;


        console.log("x filter", obj);
        
          _sLotProdCalibratedService
            .reportCalibratePDF(obj, this.$fun.getUserID())
            .then((r) => {

                 this.$fun.downloadFile(
                    r.data,
                    this.$const.TypeFile.PDF,
                    obj.NameReport
                    );
              
            });
      }
    },
    exportExcel(xFlag, NameReport) {
    
      this.dialogSuppling = true;
      this.xFlag = xFlag;
      this.NameReport = NameReport;
    
    },

    ReportDownloadExcel() {

       if(this.xFlag == 1 ) {
        let parametervalue = [];
        parametervalue.push(this.filter.DateBegin)
        parametervalue.push(this.filter.DateEnd)

        let parametergen = " @dateBegin,@dateEnd ";
        this.downloadReport("FORMATO DE ABASTECIMIENTO DE FRUTA LÍNEA USA. - EMPAQUE","REPORT_FORMAT_SUPPLY_PACKING_USA",parametervalue,parametergen);
      }
      

      if(this.xFlag == 2 ) {
        let parametervalue = [];
        parametervalue.push(this.filter.DateBegin)
        parametervalue.push(this.filter.DateEnd)

        let parametergen = " @dateBegin,@dateEnd ";
        this.downloadReport("FORMATO DE ABASTECIMIENTO DE FRUTA LÍNEA EUR. - EMPAQUE","REPORT_FORMAT_SUPPLY_PACKING_EUR",parametervalue,parametergen);
      }
       
      if (this.xFlag == 3) {
          
          let parametervalue = [];

          parametervalue.push(this.filter.DateBegin)
          parametervalue.push(this.filter.DateEnd)

          let parametergen = " @dateBegin,@dateEnd ";
          this.downloadReport("EMPAQUE EUROPA - BD PRODUCCION","REPORT_PRODUCTION_PACKING_EUR",parametervalue,parametergen);

      } 

      if (this.xFlag == 4) {
          
          let parametervalue = [];

          parametervalue.push(this.filter.DateBegin)
          parametervalue.push(this.filter.DateEnd)

          let parametergen = " @dateBegin,@dateEnd ";
          this.downloadReport("EMPAQUE EUROPA - SALDOS","FR_REPORT_BALANCE_SALDO_EUR",parametervalue,parametergen);

      } 

      if (this.xFlag == 5) {
          
          let parametervalue = [];

          parametervalue.push(this.filter.DateBegin)
          parametervalue.push(this.filter.DateEnd)

          let parametergen = " @dateBegin,@dateEnd ";
          this.downloadReport("EMPAQUE USA - SALDOS","FR_REPORT_BALANCE_SALDO_USA",parametervalue,parametergen);

      }


    },

    viewDialogProduction() {
      this.$fun.alert("Seguro de descargar?", "question").then((resp) => {
        if (resp.value) {
          this.dialogProduction = true;
        }
      });
    },

    viewDialogHidro() {
      this.$fun.alert("Seguro de descargar?", "question").then((resp) => {
        if (resp.value) {
          let obj = {
            DateBegin: new Date(),
            DateEnd: new Date(),
          };
          _sHydrothermalTreatmentReport
            .list(obj, this.$fun.getUserID())
            .the((r) => {
              if (r.status == 200) {
                _sHydrothermalTreatmentReport
                  .PrfHydrothermalTreatmentReportDownload(
                    r.data,
                    this.$fun.getUserID()
                  )
                  .then((re) => {
                    this.$fun.downloadFile(
                      re.data,
                      this.$const.TypeFile.EXCEL,
                      this.$fun.getUserID()
                    );
                  });
              }
            });
        }
      });
    },

    PrfProductionLinePackingDownload() {
      ProductionLinePackingService.list(
        this.filterProduction,
        this.$fun.getUserID()
      ).then((resp) => {
        if (resp.status == 200) {
          console.log("received ", resp.data);
          ProductionLinePackingService.PrfProductionLinePackingDownload(
            resp.data,
            this.$fun.getUserID()
          ).then((r) => {
            console.log("resp.dataaaaaa ", r.data);
            this.$fun.downloadFile(
              r.data,
              this.$const.TypeFile.EXCEL,
              this.$fun.getUserID()
            );
          });
        }
      });
    },

    viewDialogSuppling() {
      this.$fun.alert("Seguro de descargar?", "question").then((resp) => {
        if (resp.value) {
          this.dialogSuppling = true;
        }
      });
    },

    PrfSupplingFruitExcel() {
      SupplingFruitService.list(
        this.filterSuppling,
        this.$fun.getUserID()
      ).then((resp) => {
        if (resp.status == 200) {
          SupplingFruitService.PrfSupplingFruitExcel(
            resp.data,
            this.$fun.getUserID()
          ).then((r) => {
            this.$fun.downloadFile(
              r.data,
              this.$const.TypeFile.EXCEL,
              this.$fun.getUserID()
            );
          });
        }
      });
    },

    viewDialogHidroPdf() {
      this.$fun.alert("¿Seguro de descargas?", "question").then((r) => {
        if (r.value) {
          this.dialogHydroPdf = true;
        }
      });
    },

    HidroReportPdfDownload() {
      _sHydrothermalTreatmentReport
        .prfHydrothermalTreatmentReportPdf(
          this.filterHydro,
          this.$fun.getUserID()
        )
        .then((resp) => {
          if (resp.status == 200) {
            this.$fun.downloadFile(
              resp.data,
              this.$const.TypeFile.PDF,
              "Tratamiento hidrotermico"
            );
          }
        });
    },

    downloadReport(QryNameReport,qryname,parametervalue,parametergen) {
				 console.log("QryNameReport",QryNameReport);

				this.report.QryNameReport = QryNameReport	;  //this.DateBegin + "," + this.DateEnd + "," + this.number;
				this.report.QryParamsArray = parametergen	;  //this.DateBegin + "," + this.DateEnd + "," + this.number;
				this.report.QryParamsArrayValue = parametervalue.join(","); //this.DateBegin + "," + this.DateEnd + "," + this.number;
				this.report.QryNameProcedure = qryname;
					//this.report.QryDatabaseNameName + ".." + this.report.QryNameSp;

				this.processing = true;
				_sQryConfigurationService
					.downloadexcel(this.report, this.$fun.getUserID())
					.then(
						(r) => {
							if (r.status == 200) {
								this.processing = false;

								this.$fun.downloadFile(
									r.data,
									this.$const.TypeFile.EXCEL,
									 QryNameReport
								);
							}
						},
						(e) => {
							//this.messageProcessing = e.response.data.Message;
							this.processing = true;
						}
					);

			 
			},
  },
};
</script>