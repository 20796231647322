import Service from "../Service";

const resource = "supplingfruit/"

export default {

    list(obj, requestID) {
        return Service.post(resource + "list", obj, {
            params: { requestID },
        });
    },

    PrfSupplingFruitExcel(obj, requestID) {
        return Service.post(resource + "PrfSupplingFruitExcel", obj, {
            responseType: "blob",
            headers: {
                "Content-Type": "application/json",
            },
            params: { requestID },
        });
    }

}