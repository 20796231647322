import Service from "../Service";

const resource = "ShippingProductionReport/"

export default {

    list(obj, requestID) {
        return Service.post(resource + "shippingProductionReportList", obj, {
            params: { requestID },
        });
    },

    PrfProductionLinePackingDownload(obj, requestID) {
        return Service.post(resource + "shippingProductionReportDownload", obj, {
            responseType: "blob",
            headers: {
                "Content-Type": "application/json",
            },
            params: { requestID },
        });
    }

}